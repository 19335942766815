<div nz-row nzJustify="space-between" class="header">
  <div nz-col [nzXs]="22" [nzSm]="22" [nzMd]="16">
    <ng-content select="[headerButton]"></ng-content>
    <ng-content select="[breadcrumb]"></ng-content>
  </div>
  <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="8" class="header-user" *ngIf="user">
    <nz-space nzAlign="center" nzSize="middle">
      <ng-template *ngIf="false">
        <nz-badge [nzDot]="true" *nzSpaceItem>
          <i nz-icon nzType="bell" [nzTheme]="'fill'" class="notifications-icon"></i>
        </nz-badge>
      </ng-template>
      <span nz-dropdown [nzDropdownMenu]="userMenu" nzTrigger="click" class="user-menu" *nzSpaceItem>
        <nz-space nzAlign="center" nzSize="middle">
          <span nz-icon nzType="down" [nzTheme]="'outline'" class="user-menu-arrow"></span>
          <nz-avatar nzIcon="user" [nzSrc]="user.attributes.avatar" [nzSize]="40" *nzSpaceItem></nz-avatar>
          <span *nzSpaceItem class="user-fullname">{{ user.fullname() }}</span>
        </nz-space>
      </span>
    </nz-space>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item [routerLink]="['/app', 'profile']">My Profile</li>
        <li nz-menu-item *ngIf="colorMode === 'dark'" (click)="switchToColor('light')">Switch to light mode</li>
        <li nz-menu-item *ngIf="colorMode === 'light'" (click)="switchToColor('dark')">Switch to dark mode</li>
        <li nz-menu-divider></li>
        <li nz-menu-item (click)="logout()">Log Out</li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <div nz-col [nzXs]="2" [nzSm]="2" [nzMd]="0" [nzLg]="0" [nzXl]="0" [nzXXl]="0" class="mobile-menu-trigger">
    <a (click)="showMenu()">
      <span nz-icon nzType="menu" [nzTheme]="'outline'"></span>
    </a>
  </div>
</div>
<div [class]="(menuVisible) ? 'fullscreen-menu-wrapper visible' : 'fullscreen-menu-wrapper'">
  <div class="close-menu" (click)="hideMenu()">
    <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
  </div>
  <div class="menu-content">
    <ul>
      <li [routerLink]="['/app','profile']" (click)="hideMenu()">EMPIST Account</li>
      <li [routerLink]="['/app','dashboard']" (click)="hideMenu()">Dashboard</li>
      <li [routerLink]="['/app','solutions']" (click)="hideMenu()">Solutions</li>
      <li class="divider"><nz-divider></nz-divider></li>
      <li *ngIf="user">
        <ul class="user-menu-mobile">
          <li [routerLink]="['/app', 'profile']" class="user-fullname">
            <span>
              <nz-avatar nzIcon="user" [nzSrc]="user.attributes.avatar"></nz-avatar>
            </span>
            <span>{{ user.fullname() }}</span>
          </li>
          <li *ngIf="colorMode === 'dark'" (click)="switchToColor('light')">Switch to light mode</li>
          <li *ngIf="colorMode === 'light'" (click)="switchToColor('dark')">Switch to dark mode</li>
          <li (click)="logout()" class="logout-btn">Log Out</li>
        </ul>
      </li>
    </ul>
  </div>
</div>
