import { Component, DestroyRef, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output  } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import FroalaHelper from 'src/app/utilities/helpers/froala';
import { UploadsService } from 'src/app/services/uploads.service';
import { UsersService } from 'src/app/services/users.service';
import { Upload } from 'src/app/utilities/models/upload/upload';

@Component({
  selector: 'froala-editor',
  templateUrl: './froala.component.html',
  styleUrl: './froala.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FroalaComponent)
    },
  ]
})
export class FroalaComponent implements ControlValueAccessor, OnInit {
  disabled = false;
  private _text: string = '';
  private touched = false;
  private onChange = (value: any) => {};
  private onTouched = () => {};

  @Output() uploadedFile = new EventEmitter<Upload>();

  @Input() options: any; // https://froala.com/wysiwyg-editor/docs/options/

  @Input()
  get text(): any { return this._text; }
  set text(value: any) {
    if (this._text !== value) {
      this._text = value;
      this.writeValue(this._text);
    }
  }

  writeValue(value: any) {
    this.text = value;
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;

    if (this.disabled) {
      this.elementRef.nativeElement.classList.add('is-disabled');
    } else {
      this.elementRef.nativeElement.classList.remove('is-disabled');
    }
  }

  editor: any;
  froalaEditorOptions: Object;
  isFroalaVisible: boolean = true;
  private pref_dark_mode: boolean = false;

  constructor(public elementRef: ElementRef,
              private destroyRef: DestroyRef,
              private uploadsService: UploadsService,
              private userService: UsersService) {}

  ngOnInit() {
    this.observeDarkModePref();
  }

  private initializeFroalaEditor(): Object {
    return {
      ...FroalaHelper.getConfig({ placeholderText: 'Start typing…' }),
      theme: (this.pref_dark_mode === true) ? 'dark' : null,
      toolbarButtons: ['bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertTable'],
      toolbarSticky: false,
      wordCounterCount: false,
      ...this.options,

      events: {
        ...this.options?.events,

        'file.beforeUpload': (files: FileList) => {
          if (!files.length) return false;

          const payload = this.prepareUploadPayload(files[0], 'attachment');
          this.uploadsService.create(payload).subscribe({
            next: (response: any) => {
              this.uploadedFile.emit(new Upload(response.data, response.included));
              this.editor.events.trigger('blur', [], true);

              return false;
            }
          });
          return false;
        },
        'image.beforeUpload': (files: FileList) => {
          if (!files.length) return false;

          const payload = this.prepareUploadPayload(files[0], 'inline');
          this.uploadsService.create(payload).subscribe({
            next: (response: any) => {
              const url = response.data.attributes.permalink;
              this.editor.image.insert(url, false, null, this.editor.image.get(), { link: url });

              return false;
            },
            error: (error) => {
              console.error(error);
            }
          });

          return false;
        },
        'initialized': (editor: any) => {
          this.editor = editor.getEditor();
        },
      },
    }
  }

  private observeDarkModePref() {
    this.userService.prefDarkMode
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (prefDarkMode: boolean) => {
          if (typeof prefDarkMode !== undefined && this.pref_dark_mode !== prefDarkMode) {
            this.pref_dark_mode = !!prefDarkMode;
          }

          this.setFroalaColorMode();
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  private prepareUploadPayload(file: File, userFor: string) {
    const payload = new FormData();
    payload.append('used_for', userFor);
    payload.append('uploaded_file', file);

    return payload;
  }

  private setFroalaColorMode() {
    /**
     * https://github.com/froala/angular-froala-wysiwyg/issues/66#issuecomment-1068518889
     *
     * Froala theme CANNOT be changed on the fly!
     * So, if the user switches color mode, we need to DESTROY and INIT again!
     */
    if (this.editor) {
      this.isFroalaVisible = false;
      setTimeout(() => {
        this.isFroalaVisible = true;
      }, 10);
    }

    this.froalaEditorOptions = this.initializeFroalaEditor();
  }
}
