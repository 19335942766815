import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { TicketsService } from "src/app/services/tickets.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ServicesService } from "src/app/services/services.service";
import Service from "src/app/utilities/models/service/service";
import { ServiceFields } from "src/app/utilities/models/service/serviceField";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import { User } from "src/app/utilities/models/user/user";
import { UsersService } from "src/app/services/users.service";
import { LoaderService } from "src/app/services/loader.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import InstalledServiceValue from "src/app/utilities/models/service/installedServiceValue";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import ServiceHelper from 'src/app/utilities/helpers/serviceHelper';
import { ServiceAddRequestData, ServiceAddRequestDetails, ServiceAddRequestDto } from 'src/app/utilities/models/dto/serviceAddRequestDto';
import { Permission } from 'src/app/utilities/models/permissions/permission';

interface IModalData {
  service: Service;
  serviceMetric: ServiceMetric;
  rows: any[]
}

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {
  addServiceForm: FormGroup;
  formControls: ServiceFields = { repeatable: [], nonRepeatable: [] };
  repeatableRowsIndex: number = 0;
  fieldRows: number[] = [0];
  loggedInUser: User;
  accountId: number;
  loaderVisible: boolean = false;
  submittingRequest: boolean = false;
  autocompleteOptions: any = {};
  filteredAutocompleteOptions: any = {};
  office365Licenses: any[];
  permission: Permission | undefined;

  constructor(private ticketService: TicketsService,
              private serviceService: ServicesService,
              private userService: UsersService,
              private loaderService: LoaderService,
              private msg: NzMessageService,
              private destroyRef: DestroyRef,
              private modalRef: NzModalRef,
              @Inject(NZ_MODAL_DATA) readonly nzModalData: IModalData) {}

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    if(this.loggedInUser?.relationships?.account?.id) {
      this.accountId = +this.loggedInUser.relationships.account.id;
    }
    this.getFieldsByServiceMetric(this.nzModalData.serviceMetric);
    this.permission = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');

    this.repeatableRowsIndex = 0;
    this.fieldRows = [0];
    this.initForm();

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      })
  }

  getServiceMetricAddButtonTitle(): string {
    return this.nzModalData.service.getServiceAddButtonTitle(this.nzModalData.serviceMetric) ?? '';
  }

  closeModal() {
    this.modalRef.destroy();
  }

  getFieldsByServiceMetric(serviceMetric: ServiceMetric) {
    this.formControls = ServiceHelper.getAddActionFieldsByService(serviceMetric);
  }

  initForm() {
    this.addServiceForm = new FormGroup({});

    this.formControls.nonRepeatable.forEach((control: any) => {
      this.addControl(control);
    });

    this.formControls.repeatable.forEach((control: any) => {
      this.addControl(control, this.repeatableRowsIndex);
    });
  }

  disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  onAutoCompleteChange(e: Event, control: any, index: number = 0) {
    const value = (e.target as HTMLInputElement).value;
    switch(control.attributes.name.toLowerCase()){
      case 'product':
        break;
      case 'email':
        this.filteredAutocompleteOptions.email = this.autocompleteOptions.email.filter((option: string) => option.includes(value));
        break;
      default:
        break;
    }
  }

  getAutoCompleteOptions(control: any) {
    this.autocompleteOptions[control.attributes.name] = [];
    this.filteredAutocompleteOptions[control.attributes.name] = [];
    let uniqueOptions: any[] = [];

    switch(control.attributes.name.toLowerCase()){
      case 'product':
        this.serviceService.getInstalledServiceDetails(31, 7)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (response: any) => {
              let isv: any;
              response.data.forEach((row: any) => {
                const data = row.attributes?.value?.map((row: any, index: number) => { return { id: index, ...row } })
                row.attributes.value = data;
                isv = new InstalledServiceValue(row, response.included);
              })
              this.office365Licenses = isv?.attributes?.value ?? [];
              uniqueOptions = this.office365Licenses.map((row: any) => row.product_name).sort().map((row: string) => { return { value: row, label: row } })
              this.autocompleteOptions[control.attributes.name] = [...uniqueOptions];
              this.filteredAutocompleteOptions[control.attributes.name] = [...this.autocompleteOptions[control.attributes.name]];
            }
          })
        break;
      case 'email':
        let accountId = this.loggedInUser.relationships?.account?.id;
        if(accountId) {
          let reportArr: ReportParam[] = [];
          reportArr.push({ "key":"accounts.id", "operator": ["eq"], "value": [accountId] });
          reportArr.push({ "key":"active", "operator":["eq"], "value":"true"});
            this.userService.getAccountUsers(reportArr)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe({
                next: (response: any) => {
                  uniqueOptions = response.data.map((row: any) => {
                    return { value: row.attributes.email, label: `${row.attributes.email} (${row.attributes.fullname})` }
                  }) ?? [];
                  this.autocompleteOptions[control.attributes.name] = [...uniqueOptions];
                  this.filteredAutocompleteOptions[control.attributes.name] = [...this.autocompleteOptions[control.attributes.name]];
                }
              })
        }
        break;
      default:
        break;
    }
  }

  removeControl(control: any, index?: number) {
    this.addServiceForm.removeControl(this.getControlName(control, index));
  }

  addSetOfControls() {
    this.repeatableRowsIndex++;
    this.formControls.repeatable.forEach((control: any) => {
      this.addControl(control, this.repeatableRowsIndex);
    });
    this.addFieldRow();
  }

  removeSetOfControls(index: number) {
    this.formControls.repeatable.forEach((control: any) => {
      this.removeControl(control, index);
    });
    this.removeFieldRow(index);
  }

  getControlName(control: any, index: number = 0): string {
    return index > 0 ? control.attributes.column + '_' + index.toString() : control.attributes.column;
  }

  addFieldRow() {
    this.fieldRows.push(this.repeatableRowsIndex);
  }

  removeFieldRow(index: number) {
    const idx = this.fieldRows.findIndex(item => item == index);
    if(idx > -1) {
      this.fieldRows.splice(idx, 1);
    }
  }

  onSubmit() {
    if(!this.addServiceForm.valid){
      this.msg.error('Please fill in all the required fields', {
        nzDuration: 5000,
        nzPauseOnHover: true
      });
      return;
    }

    if(this.accountId){
      const payload: ServiceAddRequestDto = this.prepareAddRequestPayload();

      this.loaderService.setProcessing(true);
      this.loaderService.setLoaderVisible(true);
      this.loaderService.setLoadingText('Your request is being submitted.');
      this.loaderService.setLoadingSecondaryText('');
      this.loaderService.setLoadedText('Your request has been submitted successfully!');
      this.loaderService.setLoadedSecondaryText('');

      this.submittingRequest = true;
      this.serviceService.serviceAddRequest(payload).subscribe({
        next: (response: any) => {
          this.addServiceForm.reset();
          this.loaderService.setProcessing(false);
          this.submittingRequest = false;
          if(response.data.id){
            setTimeout(() => {
              this.loaderService.setLoaderVisible(false);
              this.closeModal();
            }, 2000)
          }
        },
        error: (error) => {
          console.log(error);
          this.submittingRequest = false;
          this.loaderService.setProcessing(false);
          this.loaderService.setLoaderVisible(false);
        }
      });
    }
  }

  private addControl(control: any, index: number = 0) {
    const controlName = this.getControlName(control, index);
    switch (control.attributes.type) {
      case 'select':
        this.addServiceForm.addControl(controlName, new FormControl<number | undefined>( undefined ));
        break;
      case 'autocomplete':
        this.addServiceForm.addControl(controlName, new FormControl<string>(''));
        this.getAutoCompleteOptions(control);
        break;
      case 'input':
      case 'textarea':
      case 'date':
      case 'wysiwyg':
        this.addServiceForm.addControl(controlName, new FormControl<string>(''));
        break;
    }
    if (control.attributes.required) {
      this.addServiceForm.controls[controlName].setValidators(Validators.required);
    }
    if (control.attributes.name == 'email') {
      if(control.attributes.required){
        this.addServiceForm.controls[controlName].setValidators(Validators.compose([Validators.email,Validators.required]));
      }else{
        this.addServiceForm.controls[controlName].setValidators(Validators.email);
      }
    }
  }

  private prepareAddRequestPayload(): ServiceAddRequestDto {
    const comments = this.addServiceForm.get('comments')?.value;
    const rows = this.prepareRowsToAdd();
    const data: ServiceAddRequestData = {
      type: 'contact_requests',
      attributes: {
        "is_removal": false,
        "notes": comments
      },
      relationships: {
        service_metric: {
          data: {
            id: this.nzModalData.serviceMetric.id,
            type: 'service_metrics'
          }
        }
      },
      nested_attributes: {
        contact_request_details: {
          data: rows
        }
      }
    }

    return new ServiceAddRequestDto(data);
  }

  private prepareRowsToAdd(): ServiceAddRequestDetails[] {
    const rows: ServiceAddRequestDetails[] = [];
    this.fieldRows.forEach((rowIndex: number) => {
      const suffix = rowIndex > 0 ? '_' + rowIndex : '';
      const attributesData: any = {};
      if(this.addServiceForm.get('col1'+suffix)?.value) attributesData['col1'] = this.addServiceForm.get('col1'+suffix)?.value;
      if(this.addServiceForm.get('col2'+suffix)?.value) attributesData['col2'] = this.addServiceForm.get('col2'+suffix)?.value;
      if(this.addServiceForm.get('col3'+suffix)?.value) attributesData['col3'] = this.addServiceForm.get('col3'+suffix)?.value;
      if(this.addServiceForm.get('col4'+suffix)?.value) attributesData['col4'] = this.addServiceForm.get('col4'+suffix)?.value;
      if(this.addServiceForm.get('col5'+suffix)?.value) attributesData['col5'] = this.addServiceForm.get('col5'+suffix)?.value;

      const serviceDetail: ServiceAddRequestDetails = {
        type: 'contact_request_details',
        attributes: attributesData
      }
      rows.push(serviceDetail);
    })

    return rows;
  }
}
