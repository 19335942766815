import { Component, DestroyRef, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { UsersService } from "src/app/services/users.service";
import { UserUpdateDto } from "src/app/utilities/models/dto/userUpdateDto";
import { User } from "src/app/utilities/models/user/user";

@Component({
  selector: 'mainHeader',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  colorMode: string = 'light';
  menuVisible: boolean = false;
  user: User | undefined;

  constructor(private userService: UsersService,
              private authService: AuthService,
              private router: Router,
              private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    this.userService.prefDarkMode
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (prefDarkMode) => {
          this.colorMode = (prefDarkMode === true) ? 'dark' : 'light';
        }
      });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/info']).then(_ => false);
  }

  showMenu() {
    this.menuVisible = true;
  }

  hideMenu() {
    this.menuVisible = false;
  }

  switchToColor(mode: string) {
    const pref_dark_mode = (mode === 'light') ? false : true;
    const payload = this.prepareSinglePayload('pref_dark_mode', pref_dark_mode);
    this.userService.updateUser(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.userService.getCurrentUser(),
        error: (error: Error) => { console.log(error) },
      });
  }

  private prepareSinglePayload(attributeChanged: string, value: boolean): UserUpdateDto{
    const userUpdateAttributes = {
      [attributeChanged]: value
    }

    return new UserUpdateDto(this!.user!.id, 'users', userUpdateAttributes, {});
  }
}
