import { Component, DestroyRef, OnInit } from '@angular/core';
import { UsersService } from "src/app/services/users.service";
import { TicketsService } from "src/app/services/tickets.service";
import { LoaderService } from "src/app/services/loader.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { User } from "src/app/utilities/models/user/user";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TicketCreateDto, TicketCreateDtoRelationship } from "src/app/utilities/models/dto/ticketCreateDto";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ServicesService } from "src/app/services/services.service";
import Service from "src/app/utilities/models/service/service";

@Component({
  selector: 'app-details-request',
  templateUrl: './detailsRequest.component.html',
  styleUrls: ['./detailsRequest.component.scss']
})
export class DetailsRequestComponent implements OnInit {
  loggedInUser: User;
  permission: Permission | undefined;
  editorOptions: Object;
  editor: any;
  service: Service;
  services: Service[] = [];
  requestChangeForm: FormGroup;
  loaderVisible: boolean = false;
  routeParams: Params;

  constructor(private userService: UsersService,
              private ticketService: TicketsService,
              private loaderService: LoaderService,
              private serviceService: ServicesService,
              private router: Router,
              private destroyRef: DestroyRef,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.editorOptions = {
      heightMin: 200,
      heightMax: 500
    };
    this.loggedInUser = this.userService.loggedInUser;
    this.permission = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');
    this.requestChangeForm = this.getFormGroup();
    this.setForm();

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      })
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.routeParams = params;
        this.serviceService.services$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (services: Service[]) => {
              if(services.length == 0) {
                this.getServices();
              }else{
                this.services = services;

                const service: Service | undefined = this.services.find((s: Service) => +s.id == this.routeParams['id']);
                if(service) this.service = service;
              }
            }
          });
      }
    })
  }

  getServices() {
    this.serviceService.getServices(1000,1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          const services = response.data.map((bsData: any) => new Service(bsData, response.included));
          this.serviceService.setServices(services);
          const serviceMetrics = response.included?.filter((includedObj: any) => includedObj.type == 'service_metric');
          this.serviceService.setServiceMetrics(serviceMetrics);
        },
        error: (_error) => {},
        complete: () => {
        }
      })
  }

  onSubmit(){
    if(!this.requestChangeForm.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your ticket is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your ticket was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: TicketCreateDto = this.preparePayload();
    this.ticketService.createTicket(payload).subscribe({
      next: (response: any) => {
        this.requestChangeForm.reset();
        this.loaderService.setProcessing(false);
        if(response.data.attributes.code){
          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
            this.router.navigate(['/app','services']);
          }, 2000)
        }
      }
    });
  }

  getFormGroup(): FormGroup {
    return new FormGroup({
      'description': new FormControl<string>({ value: '', disabled: this.disableByProperty('description') }, Validators.required),
      'account': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('account_id') }, Validators.required),
      'requester': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('requester_id') }, Validators.required),
    });
  }

  private setForm(){
    const requesterId = this.loggedInUser?.id;
    this.requestChangeForm.get('requester')?.setValue(requesterId);
    if(this.loggedInUser?.relationships?.account){
      const accountId = this.loggedInUser.relationships.account.id;
      this.requestChangeForm.get('account')?.setValue(accountId);
    }
  }

  disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private prepareData(type: string, valueId: number){
    return { data: { id: valueId, type: type }};
  }

  private preparePayload(): TicketCreateDto{
    const ticketCreateAttributes = {
      subject: 'Request Details about ' + this.service?.attributes.title,
      description: this.requestChangeForm.get('description')?.value,
    }
    const ticketCreateRelationships: TicketCreateDtoRelationship = {
      account: this.prepareData("accounts", this.requestChangeForm.get('account')?.value),
      requester: this.prepareData("users", this.requestChangeForm.get('requester')?.value),
    }

    return new TicketCreateDto("tickets", ticketCreateAttributes, ticketCreateRelationships);
  }
}
