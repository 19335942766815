<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'services']">Services</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Request Change</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','services']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" class="request-change-container">
        <div nz-row nzJustify="center">
          <div nz-col>
            <h1><span>Services</span>Request Change</h1>
          </div>
        </div>
        <div nz-row class="support-top">
          <div nz-col nzSpan="24">
            <div nz-row nzJustify="center">
              <div nz-col>
                You can submit a request to perform a change in the provided services using the form below
              </div>
            </div>
            <nz-divider></nz-divider>
            <form class="request-change-form"
                  nz-form
                  nzLayout="vertical"
                  [formGroup]="requestChangeForm"
                  (ngSubmit)="onSubmit()">
              <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-form-item class="ticket-create-form-item">
                      <nz-form-label nzFor="description" nzRequired>
                        Please describe the desired change
                      </nz-form-label>
                      <nz-form-control>
                        <froala-editor formControlName="description" [options]="editorOptions"></froala-editor>
                      </nz-form-control>
                    </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="24" class="actions-container">
                  <button nz-button type="button" nzType="default" nzShape="round" nzSize="large" [routerLink]="['/app','services']">Cancel</button>
                  <button nz-button type="submit" nzType="primary" nzShape="round" nzSize="large" [disabled]="!requestChangeForm.valid">Send Request</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <custom-loader *ngIf="loaderVisible"></custom-loader>
  </nz-content>
</nz-layout>
