import { Component, DestroyRef, OnInit } from '@angular/core';
import { InvoicesService } from "src/app/services/invoices.service";
import { Invoice } from "src/app/utilities/models/invoice/invoice";
import { ActivatedRoute, Params } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  invoice: Invoice;
  subtotal: number = 0;
  pref_dark_mode: boolean = false;

  constructor(private invoiceService: InvoicesService,
              private activateRoute: ActivatedRoute,
              private destroyRef: DestroyRef,
              private userService: UsersService) {}

  ngOnInit() {
    this.activateRoute.params
      .subscribe({
          next: (params: Params) => {
            this.get_invoice(params['id']);
          }
        }
      );

    this.userService.prefDarkMode
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (prefDarkMode: boolean) => {
          if (typeof prefDarkMode !== undefined && this.pref_dark_mode !== prefDarkMode) {
            this.pref_dark_mode = !!prefDarkMode;
          }
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  private get_invoice(id: number) {
    this.invoiceService.get_invoice(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.invoice = new Invoice(response.data, response.included);
          this.invoice.relationships?.line_items?.forEach(item => {
            if (item.attributes.amount){
              this.subtotal += item.attributes.amount;
            }
          })
        },
        error: () => {

        }
      })
  }
}
