import { Component, DestroyRef, OnInit } from '@angular/core';
import { UsersService } from "src/app/services/users.service";
import { LoaderService } from "src/app/services/loader.service";
import { User } from "src/app/utilities/models/user/user";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ChangeRequestCreateDto } from "../../../../utilities/models/dto/changeRequestCreateDto";
import { ServicesService } from "../../../../services/services.service";

@Component({
  selector: 'app-change-request',
  templateUrl: './changeRequest.component.html',
  styleUrls: ['./changeRequest.component.scss']
})
export class ChangeRequestComponent implements OnInit {
  loggedInUser: User;
  permission: Permission | undefined;
  editorOptions: Object;
  requestChangeForm: FormGroup;
  loaderVisible: boolean = false;

  constructor(private userService: UsersService,
              private loaderService: LoaderService,
              private serviceService: ServicesService,
              private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.editorOptions = {
      heightMin: 200,
      heightMax: 500
    };
    this.loggedInUser = this.userService.loggedInUser;
    this.permission = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');
    this.requestChangeForm = this.getFormGroup();
    this.setForm();

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      })
  }

  onSubmit(){
    if(!this.requestChangeForm.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your ticket is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your ticket was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: ChangeRequestCreateDto = this.prepareChangeRequestPayload();
    this.serviceService.changeRequest(payload).subscribe({
      next: (response: any) => {
        this.requestChangeForm.reset();
        this.loaderService.setProcessing(false);
        if(response.data.id){
          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
          }, 2000)
        }
      }
    });
  }

  getFormGroup(): FormGroup {
    return new FormGroup({
      'subject': new FormControl<string>({ value: '', disabled: this.disableByProperty('subject') }, Validators.required),
      'description': new FormControl<string>({ value: '', disabled: this.disableByProperty('description') }, Validators.required),
      'account': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('account_id') }, Validators.required),
      'requester': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('requester_id') }, Validators.required),
    });
  }

  private setForm(){
    this.requestChangeForm.get('subject')?.setValue('Change Request');
    const requesterId = this.loggedInUser?.id;
    this.requestChangeForm.get('requester')?.setValue(requesterId);
    if(this.loggedInUser?.relationships?.account){
      const accountId = this.loggedInUser.relationships.account.id;
      this.requestChangeForm.get('account')?.setValue(accountId);
    }
  }

  disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private prepareChangeRequestPayload(): ChangeRequestCreateDto{
    const ticketCreateAttributes = {
      subject: this.requestChangeForm.get('subject')?.value,
      description: this.requestChangeForm.get('description')?.value
    }

    return new ChangeRequestCreateDto(ticketCreateAttributes);
  }
}
