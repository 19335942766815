<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'dashboard']">Dashboard</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Account Management</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','dashboard']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col>
        <h1>Account Management</h1>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col>
        We are here to ensure smooth service delivery, communication, and problem resolution.
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" class="account-management-actions-wrapper">
        <nz-divider></nz-divider>

        <ul class="account-management-actions-list">
          @if (hasTicketChangeStatusPermission) {
            <li class="account-management-actions-list-item">
              <nz-card [routerLink]="['/app', 'account-management', 'escalation']">
                <div>
                  <dw-icon type="dw:escalate" class="card-icon"></dw-icon>
                </div>
                <div>
                  <h2>Ticket Escalation</h2>
                  <p>Use this to escalate a ticket for priority resolution.</p>
                </div>
              </nz-card>
            </li>
          }

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'biling-and-invoice-assistance']">
              <div>
                <dw-icon [type]="billingCard.icon" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>{{ billingCard.title }}</h2>
                <div class="description">
                  <p>{{ billingCard.description }}</p>
                  <span (click)="onShowMoreInfo(billingCard, $event)">more</span>
                </div>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'share-feedback']">
              <div>
                <dw-icon [type]="feedbackCard.icon" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>{{ feedbackCard.title }}</h2>
                <div class="description">
                  <p>{{ feedbackCard.description }}</p>
                  <span (click)="onShowMoreInfo(feedbackCard, $event)">more</span>
                </div>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'issue-resolution-and-management']">
              <div>
                <dw-icon [type]="issuesCard.icon" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>{{ issuesCard.title }}</h2>
                <div class="description">
                  <p>{{ issuesCard.description }}</p>
                  <span (click)="onShowMoreInfo(issuesCard, $event)">more</span>
                </div>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'upgrades']">
              <div>
                <dw-icon [type]="upgradesCard.icon" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>{{ upgradesCard.title }}</h2>
                <div class="description">
                  <p>{{ upgradesCard.description }}</p>
                  <span (click)="onShowMoreInfo(upgradesCard, $event)">more</span>
                </div>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'contract-management']">
              <div>
                <dw-icon [type]="contractsCard.icon" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>{{ contractsCard.title }}</h2>
                <div class="description">
                  <p>{{ contractsCard.description }}</p>
                  <span (click)="onShowMoreInfo(contractsCard, $event)">more</span>
                </div>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'return-an-order']">
              <div>
                <dw-icon [type]="returnCard.icon" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>{{ returnCard.title }}</h2>
                <div class="description">
                  <p>{{ returnCard.description }}</p>
                  <span (click)="onShowMoreInfo(returnCard, $event)">more</span>
                </div>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <nz-card [routerLink]="['/app', 'account-management', 'meetings']">
              <div>
                <dw-icon type="dw:events" class="card-icon"></dw-icon>
              </div>
              <div>
                <h2>Meeting List</h2>
                <p>You can create your meetings and view them in a list.</p>
              </div>
            </nz-card>
          </li>

          <li class="account-management-actions-list-item">
            <a href="https://projects.empist.com/" target="_blank">
              <nz-card>
                <div>
                  <dw-icon type="dw:projects" class="card-icon"></dw-icon>
                </div>
                <div>
                  <h2>Projects</h2>
                </div>
              </nz-card>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nz-content>
</nz-layout>
