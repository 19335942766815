import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UsersService } from './services/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Empist 360';

  constructor(private destroyRef: DestroyRef,
              private userService: UsersService) {}

  ngOnInit() {
    this.userService.prefDarkMode
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (prefDarkMode) => {
          if ((prefDarkMode === true)) {
            document.documentElement.classList.add('dark-mode');
          } else {
            document.documentElement.classList.remove('dark-mode');
          }
        }
      });
  }
}
